// import '../../App.css';
import YouTube from 'react-youtube';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import DownloadApp from './DownloadApp';
import './AboutUs.css'

export default function AboutUs() {

  function VideoPlayer() {
    const videoId = 'FSOKDHjqEoA';

    return (
      <div className='youtube-player-wrapper'>
        <YouTube videoId={videoId} className="youtube-player" />
      </div>
    );
  }
  return (
    <>
      <div className='about'>

        <div className="about-cont">
          <p className='aboutText'>
          </p>
          <VideoPlayer />

          <div >
            <span >
              <p className='aboutText'>
                Trucktronix Pty Ltd built the Truck Maps app as a free to download app available both on android play store and iOS app store, and it's ready to help you navigate with ease.


                Truck Maps has been designed by specialist engineers with a focus on simplicity and clarity. Our goal is to ensure that you as a truck driver, always know exactly where you are, what the road ahead looks like, and what obstacles may be coming up.


                We've included a range of features to help you stay safe and enhance your driving experience while driving your rig—including the option to record your journey via a built-in, live dashcam feature as you go and the ability to observe real-time live traffic alerts so you can immediately plan ahead and decide whether or not it's worth taking a different route. Additionally, we're also giving truckers access to our new and highly requested "B-Double" and "Road Train" navigational routing features.


                There's no need for any other app if all you want is an easy, safe and efficient way to get from point A to point B safely!
              </p>
            </span>
          </div>
          <br /><br />

        </div>

      </div>
      <DownloadApp />
      <br />
    </>
  );
}