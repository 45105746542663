import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import Logo from '../truckmaps_australia.png';

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [activeItem, setActiveItem] = useState('home');
  // const [activeItem, setActiveItem] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveItem('home');
    } else if (location.pathname === '/features') {
      setActiveItem('features');
    } else if (location.pathname === '/gallery') {
      setActiveItem('gallery');
    }
    else if (location.pathname === "/about") {
      setActiveItem("about");
    } else if (location.pathname === '/pricing') {
      setActiveItem('pricing');
    } else if (location.pathname === '/contact') {
      setActiveItem('contact');
    }else{
      setActiveItem('')
    }
  }, [location]);


  // useEffect(() => {
  //   var path = window.location.pathname.split('/')[1];
  //   // alert(path)
  //   if (path === 'features') {
  //     setActiveItem(path);
  //   }
  // }, [activeItem])

  const handleClickMenu = (item) => {
    setActiveItem(item); // 
    // Scroll to the top of the content
    window.scrollTo(0, 0);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="logo-area">
            <Link to="/" className="navbar-logo" onClick={() => {
              handleClickMenu('home');
              closeMobileMenu();
            }
            }>
              <img className="logo" alt="Logo" src={Logo} />
              TruckMaps
            </Link>
          </div>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className={activeItem === 'home' ? "nav-item active" : "nav-item"}
              onClick={() => handleClickMenu('home')}>
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className={activeItem === 'features' ? "nav-item active" : "nav-item"}
              onClick={() => handleClickMenu('features')}>
              <Link to="/features" className="nav-links" onClick={closeMobileMenu}>
                Features
              </Link>
            </li>
            <li className={activeItem === 'gallery' ? "nav-item active" : "nav-item"}
              onClick={() => handleClickMenu('gallery')}>
              <Link to="/gallery" className="nav-links" onClick={closeMobileMenu}>
                Gallery
              </Link>
            </li>
            <li className={activeItem === 'about' ? "nav-item active" : "nav-item"}
              onClick={() => handleClickMenu('about')}>
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                About Us
              </Link>
            </li>
            <li className={activeItem === 'pricing' ? "nav-item active" : "nav-item"}
              onClick={() => handleClickMenu('pricing')}>
              <Link to="/pricing" className="nav-links" onClick={closeMobileMenu}>
                Pricing
              </Link>
            </li>
            <li className={activeItem === 'contact' ? "nav-item active" : "nav-item"}
              onClick={() => handleClickMenu('contact')}>
              <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
                Contact Us
              </Link>
            </li>

          </ul>
        </div>
      </nav>

    </>

  );
}

export default Navbar;
