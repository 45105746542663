import React from 'react'
import { Link } from 'react-router-dom'
import './About.css'


function DownloadApp() {
  return (
    <div className="download-page">
    <h1>Download Truckmaps for your device now.</h1>
    <p>Get the app on your device from one of the following app stores:</p>
    <div className="download-links">
      <Link to="https://apps.apple.com/au/app/truck-maps/id1596651991">
        <img src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg" alt="Download on the App Store" />
      </Link>
      <Link to="https://play.google.com/store/apps/details?id=com.trucktronix.truckmaps&hl=en_AU&gl=US">
        <img src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' alt="Get it on Google Play" />
      </Link>
    </div>
  </div>
  )
}

export default DownloadApp;