import React from "react";
import '../../App.css';

export default function Pricing() {
    return (
        <div className="container">
            <header>

                <div className="text-center w-75 my-4 mx-auto">
                    <h1>Pricing</h1> <br />
                </div>
            </header>
            <main style={{
                marginRight: "35px"
            }}>
                <div className="row row-cols-1 row-cols-lg-2 justify-content-center ">

                    <div className="col col-md-6">
                        <div className="card text-center">
                            <div className="card-header">
                                <h4 className="fw-normal">Free</h4>

                            </div>
                            <div className="card-body">
                                <h1 className="card-title">
                                    $0 <small className="text-muted fw-light">/14 days
                                    </small>
                                </h1>
                                <ul className="list-unstyled py-3">
                                    <li>Download TruckMaps on your phone and enjoy your free 14 Day Trial</li>
                                </ul>
                                <button className="btn btn-lg  w-100 btn-outline-primary">Sign Up for Free
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col col-md-6">
                        <div className="card text-center">
                            <div className="card-header">
                                <h4 className="fw-normal">Premium</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title">
                                    $4.99 <small className="text-muted fw-light">/mo
                                    </small>
                                </h1>
                                <ul className="list-unstyled py-3">

                                    <li>Access our stunning truck navigator and our dash cam feature by subscribing to our premium plan.</li>

                                </ul>
                                <button className="btn btn-lg btn-primary text-white w-100 btn-outline-primary">
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <br />
            <br />

        </div>
    )

}