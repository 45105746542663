import React from 'react';
import './PrivacyPolicy.css'

function TermsOfService() {
    return (
        <div className="privacy-policy">
            <h1>TERMS OF SERVICE</h1>
            <p>By downloading or using the app, these terms will automatically apply to you, you should make sure therefore that you read them carefully before using the app. You are not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You are not allowed to attempt to extract the source code of the app, and you also should not try to translate the app into other languages, or make derivative versions. The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to Trucktronix Pty. Ltd. By using or accessing the Services, you agree to all the terms and conditions of the Agreement, and if you do not agree to all the terms and conditions of the Agreement you should not and are not permitted
                to use the Services nor the application(s).</p>

            <p>Trucktronix Pty. Ltd. is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you are paying for.</p>
            <p>The Truck Maps app stores and processes personal data that you have provided to us, in order to provide our Service. It is your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone security features and it could mean that the Truck Maps app would not work properly or at all.
            </p>
            <p>The app does use third party services that declare their own Terms and Conditions. You should be aware that there are certain things that Trucktronix Pty. Ltd. will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but Trucktronix Pty. Ltd. cannot take responsibility for the app not working at full functionality if you do not have access to Wi-Fi, and you do not have any of your data allowance left.</p>
            <p>If you are using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the app, you are accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you are using the app, please be aware that we assume that you have received permission from the bill payer for using the app.</p>
            <p>Along the same lines, Trucktronix Pty. Ltd. cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged, if it runs out of battery and you cannot turn it on to avail the Service, Trucktronix Pty. Ltd. cannot accept responsibility. With respect to Trucktronix Pty. Ltd. responsibility for your use of the app, when you are using the app, it is important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Trucktronix Pty. Ltd. accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
            </p>
            <p>At some point, we may wish to update the app. The app is currently available on Android and IOS, the requirements for system(and for any additional systems we decide to extend the availability of the app to) may change, and you will need to download the updates if you want to keep using the app. Trucktronix Pty. Ltd. does not promise that it will always update the app so that it is relevant to you and/or works with the Android or IOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.</p>
            <p>Any purchased subscription plans will automatically renew unless canceled at least 24 hours before the end of the current period. Account will be charged for renewal within 24 hours prior to the end of the current period, and identify the cost of the renewal. By purchasing a Monthly subscription, you agree to an initial and recurring Monthly subscription fee at the then-current Monthly subscription rate, and you accept responsibility for all recurring charges until you cancel your subscription. You may cancel your Monthly subscription at any time, subject to the terms of our terms and conditions policy. Truck Maps uses subscription billing for users to ensure continued provision of service. As such, users are able to access all its features on subscription unless we stop providing or terminate the app. You acknowledge that in the event of Truck Maps being terminated or its services deleted, we reserve full right to not refund any subscription amount paid. In the event, you the user, cancel the subscription/s that you paid for, no refund shall be provided by us.</p>
            <p>In order to use the Service you will be required to provide your personal details. In particular, you must provide your first name, surname, phone number, e-mail address and other requested information as indicated. All information provided to us must be accurate and correct to the best of your knowledge; and not infringe on the rights of any third parties, including but not limited to, content that infringes on privacy rights or intellectual property rights, such as copyright and trademark rights. Upon successful completion the sign up process, we will provide you with an account, accessible for you by using a password of your choice.
            </p>
            <p>Trucktronix Pty. Ltd. shall not take liability for nor any responsibility for any incident, loss or damage in any forms due to use of this app or its service. The information contained on the Service is for general information purposes only. The information is provided by Trucktronix Pty. Ltd. and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Service or the information, products, services, or related graphics contained on the Service for any purpose. Any reliance you place on such information is therefore strictly at your own risk. You must as a user of this app, be obliged to follow but not limited to:
            </p>
            <ol>
                <li>Do not follow instructions that may lead to an unsafe or illegal situations.</li>
                <li>Obey all local traffic laws.</li>
                <li>Be aware that using a mobile phone or some of its features while driving may be prohibited.</li>
                <li>Do not operate while driving.</li>
                <li>The first priority while driving should be road safety.</li>
            </ol>
            <p>Using this app, you provide consent for Trucktronix Pty Ltd to access of camera, microphone, location services via use of app and/or our services.</p>
            <p>As part of the registration process, or as part of your continued use of our Services, you may be required to provide personal information about yourself (such as identification or contact details), including:</p>
            <ol>
                <li>first name and surname</li>
                <li>email address</li>
                <li>preferred username</li>
                <li>postcode</li>
            </ol>

            <h2>Changes to This Terms and Conditions</h2>
            <p>We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms of Service on this page. Trucktronix Pty. Ltd. reserves the right in its sole discretion, to modify the Agreement at any time by changing the Agreement for the Service. Please carefully read the Terms of Service and
                check back often for any changes. If you do not agree to any change to the Terms of Service then you must
                immediately stop using the Service.<br />
                These terms and conditions are effective as of 2021-10-20
            </p>
            <h2>Contact Us</h2>
            <p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact us at help@truckmaps.com</p>
            <br/>
            <br/>
        </div>
    );
};
export default TermsOfService;