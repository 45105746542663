import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Reviews from '../Review';
import DownloadApp from './DownloadApp';

function Home() {
    return (
        <>
            <HeroSection />
            <Cards />
            <Reviews />
            <DownloadApp />
            <br />
        </>
    );
}

export default Home;