const people = [
  {
    id: 1,
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959131/person-2_ipcjws.jpg',
    name: 'maria ferguson',
    title: 'office manager',
    quote:
      'Those saying it costs a bit, its tax deductable so ya get it back. Any money is worth it if it keeps ya out of trouble, looking forward to trying it out'
  },
  {
    id: 2,
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg',
    name: 'john doe',
    title: 'regular guy',
    quote:
      'I have just downloaded to travel in Sydney suburb and it helped me a lot not a lot totaly show the best route for truck.'
  },
  {
    id: 3,
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1595959121/person-1_aufeoq.jpg',
    name: 'peter smith',
    title: 'product designer',
    quote:
      "Just downloaded the app to try in Sydney Metro from Tuesday. I'm only in a Heavy Rigid, my biggest bug-bear is weight limits!"
  },
  {
    id: 4,
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg',
    name: 'susan andersen',
    title: 'the boss',
    quote:
      'Great idea and about time,for the modern day truck driver,will save lives.'
  },
];

export default people;