import React from 'react'
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
        <h1>Features of  Truckmaps </h1>
        <p>
        Truckmaps provides truck navigation services along with other features including:

        </p>
       <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <CardItem
                src='images/truck-dimensions.PNG'
                // src='../../public/images/truck-on-road.jpg'
                text="Australian and European weight and height restrictions."
              label='Features'
              path='/features'
              />
              <CardItem
                src='images/navigation.PNG'
                // src='../../public/images/truck-on-road.jpg'
                text="Australian and European truck navigation."
              label='Features'
              path='/features'
              />
                <CardItem
                src='images/truck.PNG'
                // src='../../public/images/truck-on-road.jpg'
                text="Automatic average speed camera calculator (NSW Only)."
              label='Features'
              path='/features'
              />
             
              </ul>
              <ul className='cards__items'>

              <CardItem
                src='images/stations.PNG'
                // src='../../public/images/truck-on-road.jpg'
                text="All BP, Shell and AMPOL Truck stops in Australia and their access information."
              label='Features'
              path='/features'
              />
               <CardItem
                src='images/live-traffic.jpg'
                // src='../../public/images/truck-on-road.jpg'
                text="Australia wide live traffic events update every 10 mins. (Around 20 different traffic event types including, accidents, floods, roadworks and fires)"
              label='Features'
              path='/features'
              />
              <CardItem
                src='images/bridge.jpg'
                // src='../../public/images/truck-on-road.jpg'
                text="All rest areas and weigh bridge's and their access information."
              label='Features'
              path='/features'
              />
             
              {/* <CardItem
                src='images/img-9.jpg'
                // src='../../public/images/truck-on-road.jpg'
                text="It's hard to know when you're overloaded 
                and that can end up with some hefty fines! The Truck Maps Weigh will help 
                you stay within the legal weight limits."
              label='Benefits'
              path='/features'
              /> */}
            </ul>
        </div>
    </div>
    </div>
  )
}

export default Cards;