import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Services from "./components/pages/Features";
import Footer from "./components/pages/Footer";
import FAQ from "./components/pages/FAQ";
import AboutUs from "./components/pages/AboutUs";
import Gallery from "./components/pages/Gallery";
import ContactUs from "./components/pages/ContactUs";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Pricing from "./components/pages/Pricing";
import Features from "./components/pages/Features";
import MyGallery from "./components/pages/ImageGallery";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfService from "./components/pages/TermsOfService";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/gallery" element={<MyGallery />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
