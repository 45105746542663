import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import img1 from '../../gallery/ss1.png';
import img2 from '../../gallery/ss2.png';
import img3 from '../../gallery/ss3.png';
import img4 from '../../gallery/ss4.png';
import img5 from '../../gallery/ss5.png';
import img6 from '../../gallery/ss6.png';
import img7 from '../../gallery/ss7.png';
import img8 from '../../gallery/ss8.png';
import img9 from '../../gallery/ss9.png';
import img10 from '../../gallery/ss10.png';
import img11 from '../../gallery/ss11.png';
import React from 'react'

function MyGallery() {
    const images=[
        {
            original: img1,
            thumbnail:img1,
        },
        {
            original: img2,
            thumbnail:img2,
        },
        {
            original: img3,
            thumbnail:img3,
        },
        {
            original: img4,
            thumbnail:img4,
        },
        {
            original: img5,
            thumbnail:img5,
        },
        {
            original: img6,
            thumbnail:img6,
        }, {
            original: img7,
            thumbnail:img7,
        },
        {
            original: img8,
            thumbnail:img8,
        },
        {
            original: img9,
            thumbnail:img9,
        },
        {
            original: img10,
            thumbnail:img10,
        },
        {
            original: img11,
            thumbnail:img11,
        },
        // {
        //     original: img12,
        //     thumbnail:img12,
        // },
    ];

   
  return (
    <>
    {/* <div>ImageGallery</div> */}
    <ImageGallery 
    items={images} 
    showPlayButton={true}
    showFullscreenButton={true}
    slideInterval={1000}
    slideOnThumbnailOver={true}
    showIndex={true}
    onPlay={()=>{
        alert("Slideshow is now playing!")
    }

    }

    />
    </>
  )
}

export default MyGallery

