import React, { useState } from "react";
import '../../App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactUs() {
    const [formStatus, setFormStatus] = useState('Send');

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus('Submitting...');

        const { name, email, phone, message } = e.target.elements;
        let data = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            message: message.value,
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/mail`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Failed to send an email!');
            }

            toast.success('Email sent successfully!');
            setFormStatus('Send');
            setTimeout(() => {
                e.target.reset();
            }, 3000);
        } catch (error) {
            console.error(error);
            setFormStatus('Resend');
            if (error.response && error.response.status === 400) {
                toast.error('Please fill in all required fields correctly.');
            } else {
                toast.error('An error occurred while sending email. Please try again later.');
            }
        };

    }
    return (
        <div className="contact">
            <div className="container mt-3">
                <h3 className="mb-2">Contact Us</h3>
                <br />
                {/* <h6 className="mb-3">If you have any questions or suggestions about Truck Maps, do not hesitate to contact us</h6> */}
                {/* <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us</p> */}
                <ul>📞 1300 342 147 </ul>

                <ul>📧 help@truckmaps.com.au</ul>

                <ul>📮 PO BOX 294, HORSLEY PARK, NSW, 2175, AUSTRALIA<br /><br />
                </ul>
                <h6 className='mb-3'>If you wish to enquire, please fill out the form below and we'll get back to you.
                    <br /><br /></h6>
                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                            Name<span className="visually-hidden">(required)</span>
                        </label>
                        <input className="form-control" type="text" id="name" required minLength="2" maxLength="50" />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                            Email
                        </label>
                        <input className="form-control" type="email" id="email" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                            Phone Number
                        </label>
                        <input className="form-control" type="text" id="phone" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="message">
                            Message
                        </label>
                        <textarea className="form-control" id="message" required minLength="10" maxLength="500" />
                    </div>
                    <button className="btn btn-primary" type="submit">
                        {formStatus}
                    </button>
                </form>
                <div>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>
            </div>
            <br /><br />
        </div>

    );
}