import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import './HeroSection.css';
import '../App.css';
import Pricing from "./pages/Pricing";

function HeroSection() {
    const [playVideo, setPlayVideo] = useState(false);
    const videoId = 'FSOKDHjqEoA';
    
    useEffect(() => {
        if (playVideo) {
            window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
        }
    }, [playVideo]);


    return (
        <div className="hero-container">
            {/* <video src="/videos/video-1.mp4" autoPlay loop muted/> */}
            {/* <img src="truck-on-road.jpg" alt="truck"/> */}
            {/* <div> */}
            <h2>Make more & pay less with every trip.</h2>
            <p>Avoid government fines, save taxes & increase productivity using our truck maps.</p>
            {/* </div> */} 
            <div className="hero-btns">
                <Button
                    link='/pricing'
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >
                    Try Truckmaps
                </Button>

                <Button
                    className='btns'
                    buttonStyle='btns--primary'
                    buttonSize='btn--large'
                    onClick={() => setPlayVideo(true)}
                >
                    Watch Video 
                    <i className="far fa-play-circle" />
                </Button>

            </div>
        </div>
    );
}

export default HeroSection;