import React from "react";
import { Link } from "react-router-dom";
import Logo from '../../truckmaps-logo.png'
import "./Footer.css";

function Footer() {
    const handleClickMenu = (item) => {
        // Scroll to the top of the content
        window.scrollTo(0, 0);
    };
    return (
        <div className="footer-container">
            <br />
            {/* <hr></hr> */}

            <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2> QUICK LINKS</h2>
                        {/* <Link to="/about-us"> About Us</Link> */}
                        <Link to="https://www.facebook.com/truckmaps/"> TruckMaps On Facebook</Link>
                        <Link to="/about" onClick={() => handleClickMenu()}>About TruckMaps</Link>

                        <Link to="/gallery" onClick={() => handleClickMenu()}>Gallery</Link>
                        <Link to="/contact" onClick={() => handleClickMenu()}>Contact Us</Link>
                        <Link to="/terms-of-service" onClick={() => handleClickMenu()}> Terms & Conditions</Link>
                        <Link to="/privacy-policy" onClick={() => handleClickMenu()}>Privacy Policy</Link>




                    </div>
                    <div className="footer-link-items">
                        <h2> CONTACT US</h2>
                        <ul>
                            <strong>PHONE: </strong>1300 342 147  </ul>
                        <ul>
                            <strong>EMAIL: </strong>
                            help@truckmaps.com.au
                        </ul>
                        <ul>
                            <strong>MAIL: </strong>PO BOX 294, HORSLEY PARK, 2175, NSW,
                            AUSTRALIA  </ul>
                        <ul>
                            <strong>TRADING HOURS:</strong> Mon to Fri From 9am to 5pm (AEST)</ul>

                        <ul><strong>INTERNATIONAL: </strong>+61473911911
                            TRUCKTRONIX PTY. LTD. T/A TruckMaps</ul>
                        <ul>
                            <strong>ABN:</strong> 53 637 029 817</ul>

                    </div>
            
                </div>
            </div>
            <section className="social-media">
                <div className="social-media-wrap">
                    {/* <div className="footer-logo">
                        <Link to='/' className="social-logo">
                            TruckMaps
                            <img className="logo" alt="Logo" src={Logo} />
                        </Link>

                    </div> */}
                    <small className="website-rights">
                        ©2024 TruckMaps. All rights reserved.
                    </small>
                    {/* <div className="footer">
                    © 2023 TruckMaps. All rights reserved.
    </div> */}
                    <div className="social-icons">
                        <Link
                            className="social-icon-link facebook"
                            to="https://www.facebook.com/truckmaps/"
                            target='_blank'
                            aria-label="Facebook"
                        >
                            <i className="fab fa-facebook-f" />
                        </Link>
                        <Link
                            className="social-icon-link instagram"
                            to='/'
                            target='_blank'
                            aria-label="Instagram"
                        >
                            <i className="fab fa-instagram" />
                        </Link>
                        <Link
                            className="social-icon-link youtube"
                            to='https://www.youtube.com/@trucktronixteam31'
                            target='_blank'
                            aria-label="Youtube"
                        >
                            <i className="fab fa-youtube" />
                        </Link>
                        <Link
                            className="social-icon-link twitter"
                            to='/'
                            target='_blank'
                            aria-label="Twitter"
                        >
                            <i className="fab fa-twitter" />
                        </Link>
                        <Link
                            className="social-icon-link linkedin"
                            to='/'
                            target='_blank'
                            aria-label="Linkedin"
                        >
                            <i className="fab fa-linkedin" />
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;
